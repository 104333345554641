/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background {
  background-image: url(./img/home-background.svg);
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.query {
  text-align: center;
  li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
  input {
    text-align: right;
    background: transparent;
    border-bottom: 1px solid white;
    width: 20%;
    color: white;
  }
}

.menu-btn:hover {
  background-color: black;
  color: white;
}

.submit-btn:hover {
  background-color: black;
  color: white;
}

.next-done-btn:hover {
  scale: 1.05;
}

.again-btn:hover {
  background-color: black;
  color: white;
}

.player-card {
  border-radius: 1rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
  border: 1px solid black;
  .p-header {
    -ms-flex-item-align: end;
    align-self: flex-end;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    p {
      color: white;
    }
  }
  .p-footer {
    border-radius: 0 0 1rem 1rem;
    border: 1px solid black;
    background: rgba(204, 204, 204, 0.5);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    p {
      color: black;
      text-align: center;
      opacity: none;
    }
  }
}

.p-footer-photo {
  border-radius: 0 0 1rem 1rem;
  background: rgba(0, 0, 0, 0.5);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  p {
    color: white;
    text-align: center;
    opacity: none;
  }
}

.focus-btn {
  .player-card {
    background-color: grey;
    border: 2px solid red;
  }
  .player-card-photo {
    border: 2px solid red;
  }
}

.finalized {
  -webkit-filter: none;
  filter: none;
}

.overall-box {
  padding: 0.15rem;
  border-radius: 0.15rem;
}

.dark-matter {
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(75, 168, 255, 0.1)),
      to(rgba(0, 0, 0, 0.7))
    ),
    -webkit-gradient(linear, right top, left bottom, from(#000), color-stop(#400000), to(#562683));
  background: -o-linear-gradient(rgba(75, 168, 255, 0.1), rgba(0, 0, 0, 0.7)),
    -o-linear-gradient(top right, #000, #400000, #562683);
  background: linear-gradient(rgba(75, 168, 255, 0.1), rgba(0, 0, 0, 0.7)),
    linear-gradient(to bottom left, #000, #400000, #562683);
}

.galaxy-opal {
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(75, 168, 255, 0.1)),
      to(rgba(0, 0, 0, 0.7))
    ),
    -webkit-gradient(linear, right top, left bottom, from(#188fff), color-stop(#e1a6e7), to(#f9a205));
  background: -o-linear-gradient(rgba(75, 168, 255, 0.1), rgba(0, 0, 0, 0.7)),
    -o-linear-gradient(top right, #188fff, #e1a6e7, #f9a205);
  background: linear-gradient(rgba(75, 168, 255, 0.1), rgba(0, 0, 0, 0.7)),
    linear-gradient(to bottom left, #188fff, #e1a6e7, #f9a205);
  -webkit-box-shadow: 0 0 10px #f9a205;
  box-shadow: 0 0 10px #f9a205;
}

.pink-diamond {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ff96df),
    to(#ad388a)
  );
  background: -o-linear-gradient(#ff96df, #ad388a);
  background: linear-gradient(#ff96df, #ad388a);
  -webkit-box-shadow: 0 0 10px #ff96df;
  box-shadow: 0 0 10px #ff96df;
}

.diamond {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#00aace),
    to(#005668)
  );
  background: -o-linear-gradient(#00aace, #005668);
  background: linear-gradient(#00aace, #005668);
}

.amethyst {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#a51fff),
    to(#5b1c86)
  );
  background: -o-linear-gradient(#a51fff, #5b1c86);
  background: linear-gradient(#a51fff, #5b1c86);
}

.ruby {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#e70000),
    to(#810000)
  );
  background: -o-linear-gradient(#e70000, #810000);
  background: linear-gradient(#e70000, #810000);
}

.sapphire {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#0020ce),
    to(#001068)
  );
  background: -o-linear-gradient(#0020ce, #001068);
  background: linear-gradient(#0020ce, #001068);
}

.emerald {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#05c715),
    to(#03630b)
  );
  background: -o-linear-gradient(#05c715, #03630b);
  background: linear-gradient(#05c715, #03630b);
}

.gold {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#d6bb5c),
    to(#a38829)
  );
  background: -o-linear-gradient(#d6bb5c, #a38829);
  background: linear-gradient(#d6bb5c, #a38829);
}

.silver {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#a2a2a2),
    to(#6f6f6f)
  );
  background: -o-linear-gradient(#a2a2a2, #6f6f6f);
  background: linear-gradient(#a2a2a2, #6f6f6f);
}

.bronze {
  background: #c90;
}

.toggle-size {
  background-color: white;
  margin-bottom: 3rem;
}

.toggle-label {
  font-size: large;
  font-family: "Montserrat", "Helvetica", "sans-serif";
}

.generator {
  overflow-y: auto;
}
